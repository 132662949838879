<template>
  <MobileHeaderComponent />
  <HeaderComponent />
  <router-view/>
  <FooterComponent />
</template>

<script>
  import FooterComponent from './components/FooterComponent.vue';
  import HeaderComponent from './components/HeaderComponent.vue';
  import MobileHeaderComponent from './components/MobileHeaderComponent.vue';

  export default {
    components: {
      FooterComponent,
      HeaderComponent,
      MobileHeaderComponent,
    }
  }
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

* {
  font-family: "Lato", sans-serif;
}
a {
  text-decoration: none;
}

</style>
